.form-signUp {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: fit-content;
  position: absolute;
  background-color: whitesmoke;
}
.learning {
  text-align: center;
}
.signUp {
  cursor: pointer;
}
.login {
  text-align: left;
}
.button {
  width: 100%;
}
.error-text {
  color: red;
}

.dateWrapper1 {
  width: 100%;
}
.personID {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  transition: 1s;
  text-overflow: ellipsis
}
.personID:hover {
  max-width: fit-content;
  text-overflow: unset;
}
.editMain{
  width: 60%;
}
.alexaId{
  cursor: pointer;
}
.subsTable{
  table-layout: fixed;
}
.divider{
  border: 1px solid black;
  margin: 0.5%;
}
.textHeader{
  font-weight: bold;
}
.tableHead{
  background-color:darkcyan;
  color: white;
}
.myButton {
  width: 100%;
  padding: 6px;
}
.dateWrapper {
  width: 72%;
}

.dateInput {
  width: 100%;
}

.has-search .form-control {
  padding-left: 2.375rem;
  width: 50%;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.icon-primary {
  color: "primary";
}
/* .tooltip-inner {
  background: white;
}
.tooltip1 {
  color: white;
  background-color: yellow;
} */
.tooltip > .tooltip-inner {
  background: #ffffdd;
  color: #000;
}
.tooltip.show {
  opacity: 1 !important;
}
.formControlLabel {
  margin-right: 0px !important;
}

.table-head{
  background-color:darkcyan;
  color: white;
}
.edit-modal{
  width: 100%;
  max-width: 100% !important;
}

.table-row{
  cursor: pointer;
}